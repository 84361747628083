import { isMobile } from '../utils/detect-browser';
import getBreakpointValue from "../utils/breakpoint-value";

const $ = window.jQuery;
let breakPoint = getBreakpointValue();
let map = undefined;


const getMapCenter = () => {
	let lat = -38.266974;
	let lng = 144.522250;

	if (breakPoint === 'xs' || breakPoint === 'sm') {
		lat = -38.266974;
		lng = 144.524642;
	}
	return { lat, lng };
}

export default function () {
	const { google } = window;

	const mainLat = -38.267730;
	const mainLong = 144.524160;
	const mainMarkerPath = `${$('#map').data('path')}/assets/img/main-map-marker.png`;

	const stationLat = -38.266310;
	const stationLong = 144.524840;
	const stationMarkerPath = `${$('#map').data('path')}/assets/img/station-map-marker.png`;

	const center = getMapCenter();

	const mapStyle = [
		{
			elementType: 'geometry',
			stylers: [
				{ color: '#f5f5f5' }
			]
		},
		{
			elementType: 'labels.icon',
			stylers: [
				{ visibility: 'off' }
			]
		},
		{
			elementType: 'labels.text.fill',
			stylers: [
				{ color: '#616161' }
			]
		},
		{
			elementType: 'labels.text.stroke',
			stylers: [
				{ color: '#f5f5f5' }
			]
		},
		{
			featureType: 'administrative.land_parcel',
			elementType: 'labels.text.fill',
			stylers: [
				{ color: '#bdbdbd' },
				{ lightness: 100 },
				{ visibility: 'off' }
			]
		},
		{
			featureType: 'poi',
			elementType: 'geometry',
			stylers: [
				{ color: '#eeeeee' }
			]
		},
		{
			featureType: 'poi',
			elementType: 'labels.text.fill',
			stylers: [
				{ color: '#757575' }
			]
		},
		{
			featureType: 'poi.business',
			stylers: [
				{ visibility: 'off' }
			]
		},
		{
			featureType: 'poi.park',
			elementType: 'geometry',
			stylers: [
				{ color: '#e5e5e5' }
			]
		},
		{
			featureType: 'poi.park',
			elementType: 'labels.text',
			stylers: [
				{ visibility: 'off' }
			]
		},
		{
			featureType: 'poi.park',
			elementType: 'labels.text.fill',
			stylers: [
				{ color: '#9e9e9e' }
			]
		},
		{
			featureType: 'road',
			elementType: 'geometry',
			stylers: [
				{ color: '#ffffff' }
			]
		},
		{
			featureType: 'road.arterial',
			elementType: 'labels.text.fill',
			stylers: [
				{ color: '#757575' }
			]
		},
		{
			featureType: 'road.highway',
			elementType: 'geometry',
			stylers: [
				{ color: '#dadada' }
			]
		},
		{
			featureType: 'road.highway',
			elementType: 'labels.text.fill',
			stylers: [
				{ color: '#616161' }
			]
		},
		{
			featureType: 'road.local',
			elementType: 'labels.text.fill',
			stylers: [
				{ color: '#9e9e9e' }
			]
		},
		{
			featureType: 'transit.line',
			elementType: 'geometry',
			stylers: [
				{ color: '#e5e5e5' }
			]
		},
		{
			featureType: 'transit.station',
			elementType: 'geometry',
			stylers: [
				{ color: '#eeeeee' }
			]
		},
		{
			featureType: 'water',
			elementType: 'geometry',
			stylers: [
				{ color: '#c9c9c9' }
			]
		},
		{
			featureType: 'water',
			elementType: 'labels.text.fill',
			stylers: [
				{ color: '#9e9e9e' }
			]
		}
	];

	/* eslint-disable no-unused-vars */
	map = new google.maps.Map(document.getElementById('map'), {
		center: { lat: center.lat, lng: center.lng },
		zoom: 17,
		styles: mapStyle,
		disableDefaultUI: true
	});

	const mainMarkerImage = {
		url: mainMarkerPath,
		size: new google.maps.Size(200, 203),
		scaledSize: new google.maps.Size(100, 101.5),
		origin: new google.maps.Point(0, 0),
		anchor: new google.maps.Point(30, 70),
		optimzed: false
	};

	const stationMarkerImage = {
		url: stationMarkerPath,
		size: new google.maps.Size(200, 203),
		scaledSize: new google.maps.Size(100, 101.5),
		origin: new google.maps.Point(0, 0),
		anchor: new google.maps.Point(30, 70),
		optimzed: false
	};

	new google.maps.Marker({
		position: { lat: mainLat, lng: mainLong },
		map,
		icon: mainMarkerImage
	});

	new google.maps.Marker({
		position: { lat: stationLat, lng: stationLong },
		map,
		icon: stationMarkerImage
	});


	if (isMobile()) {
		map.setOptions({ draggable: false });
	}
}

$(window).resize(() => {
	breakPoint = getBreakpointValue();

	if (map) {
		const center = getMapCenter();
		const newCenter = new window.google.maps.LatLng(center.lat, center.lng);
		map.setCenter(newCenter);
	}
});
