/**
* Setup webpack public path
* to enable lazy-including of
* js chunks
*
*/
/* ----global urls:true---- */
import 'bootstrap';
import 'slick-carousel';
import 'popper.js';
import AOS from 'aos';

import initGoogleMap from './scripts/google-maps';
import { anchorOnClick, anchorOnLoad } from './utils/anchor-scroll';

const $ = window.jQuery;


$(() => {
    // Anchor scroll
    const scrollOffset = 100;
    anchorOnClick(scrollOffset, 500);
    anchorOnLoad(scrollOffset, 500);

    AOS.init();

    $('#products').slick({
        centerMode: true,
        slidesToShow: 2,
        arrows: false,
        mobileFirst: true,
        responsive: [
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 3
                }
            },
            {
                breakpoint: 993,
                settings: 'unslick'
            }
        ]
    })
});

$(window).resize(() => {

});

// Make Google maps globally available
if (!('initMap' in window)) {
    window.initMap = initGoogleMap;
}

